//
// Component: Main Sidebar
//

.main-sidebar {
  height: 100vh;
  overflow-y: hidden;
  z-index: $zindex-main-sidebar;

  // Remove Firefox Focusring
  a {
    &:-moz-focusring {
      border: 0;
      outline: none;
    }
  }

}

.sidebar {
  height: calc(100% - (#{$main-header-height-inner} + #{$main-header-bottom-border-width}));
  overflow-y: auto;
  padding-bottom: $sidebar-padding-y;
  padding-left: $sidebar-padding-x;
  padding-right: $sidebar-padding-x;
  padding-top: $sidebar-padding-y;
}

// Sidebar user panel
.user-panel {
  position: relative;

  [class*='sidebar-dark'] & {
    border-bottom: 1px solid lighten($dark, 12%);
  }

  [class*='sidebar-light'] & {
    border-bottom: 1px solid $gray-300;
  }

  &,
  .info {
    overflow: hidden;
    white-space: nowrap;
  }

  .image {
    display: inline-block;
    padding-left: $nav-link-padding-x - .2;
  }

  img {
    height: auto;
    width: $sidebar-user-image-width;
  }

  .info {
    display: inline-block;
    padding: 5px 5px 5px 10px;
  }

  .status,
  .dropdown-menu {
    font-size: $font-size-sm;
  }
}

// Sidebar navigation menu
.nav-sidebar {
  // All levels
  .nav-item {
    > .nav-link {
      margin-bottom: .2rem;

      .right {
        @include transition(transform $transition-fn $transition-speed);
      }
    }
  }

  .nav-link > .right,
  .nav-link > p > .right {
    position: absolute;
    right: 1rem;
    top: .7rem;

    i,
    span {
      margin-left: .5rem;
    }

    &:nth-child(2) {
      right: 2.2rem;
    }
  }

  .menu-open {
    > .nav-treeview {
      display: block;
    }

    > .nav-link {
      i.right {
        @include rotate(-90deg);
      }
    }
  }

  // First Level
  > .nav-item {
    margin-bottom: 0;

    .nav-icon {
      margin-left: .05rem;
      font-size: 1.2rem;
      margin-right: .2rem;
      text-align: center;
      width: $sidebar-nav-icon-width;

      &.fa,
      &.fas,
      &.far,
      &.fab,
      &.glyphicon,
      &.ion {
        font-size: 1.1rem;
      }
    }

    .float-right {
      margin-top: 3px;
    }
  }

  // Tree view menu
  .nav-treeview {
    display: none;
    list-style: none;
    padding: 0;

    > .nav-item {
      > .nav-link {
        > .nav-icon {
          width: $sidebar-nav-icon-width;
        }
      }
    }
  }

  &.nav-child-indent {
    .nav-treeview {
      transition: padding $transition-speed $transition-fn;
      padding-left: 1rem;

      .text-sm & {
        padding-left: .5rem;
      }
    }

    &.nav-legacy {
      .nav-treeview {      
        .nav-treeview {
          padding-left: 2rem;
          margin-left: -1rem;

          .text-sm & {          
            padding-left: 1rem;
            margin-left: -.5rem;
          }
        }
      }
    }
  }

  .nav-header {
    font-size: .9rem;
    padding: $nav-link-padding-y;

    &:not(:first-of-type) {
      padding: 1.7rem 1rem .5rem;
    }
  }

  .nav-link p {
    display: inline-block;
    margin: 0;
  }
}

#sidebar-overlay {
  @include media-breakpoint-down(md) {
    .sidebar-open & {
      display: block;
    }
  }

  background-color: rgba($black, 0.1);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $zindex-main-sidebar - 1;
}

[class*='sidebar-light-'] {
  // Sidebar background color
  background-color: $sidebar-light-bg;

  // User Panel (resides in the sidebar)
  .user-panel {
    a:hover {
      color: $sidebar-light-hover-color;
    }

    .status {
      background: $sidebar-light-hover-bg;
      color: $sidebar-light-color;

      &:hover,
      &:focus,
      &:active {
        background: darken($sidebar-light-hover-bg, 3%);
        color: $sidebar-light-hover-color;
      }
    }

    .dropdown-menu {
      @include box-shadow(0 2px 4px rgba(0, 0, 0, .4));
      border-color: darken($sidebar-light-hover-bg, 5%);
    }

    .dropdown-item {
      color: $body-color;
    }
  }

  // Sidebar Menu. First level links
  .nav-sidebar > .nav-item {
    // links
    > .nav-link {
      // border-left: 3px solid transparent;
      &:active,
      &:focus {
        color: $sidebar-light-color;
      }
    }

    // Hover and active states
    &.menu-open > .nav-link,
    &:hover > .nav-link {
      background-color: $sidebar-light-hover-bg;
      color: $sidebar-light-hover-color;
    }

    > .nav-link.active {
      color: $sidebar-light-active-color;

      @if $enable-shadows {
        box-shadow: map-get($elevations, 1);
      }
    }

    // First Level Submenu
    > .nav-treeview {
      background: $sidebar-light-submenu-bg;
    }
  }

  // Section Heading
  .nav-header {
    background: inherit;
    color: darken($sidebar-light-color, 5%);
  }

  // All links within the sidebar menu
  .sidebar {
    a {
      color: $sidebar-light-color;

      &:hover {
        text-decoration: none;
      }
    }
  }

  // All submenus
  .nav-treeview {
    > .nav-item {
      > .nav-link {
        color: $sidebar-light-submenu-color;
      }

      > .nav-link.active {
        &,
        &:hover {
          background-color: $sidebar-light-submenu-active-bg;
          color: $sidebar-light-submenu-active-color;
        }
      }

      > .nav-link:hover {
        background-color: $sidebar-light-submenu-hover-bg;
      }
    }
  }

  // Flat style
  .nav-flat {
    .nav-item {
      .nav-treeview {
        .nav-treeview {
          border-color: $sidebar-light-submenu-active-bg;
        }

        > .nav-item {
          > .nav-link {
            &,
            &.active {
              border-color: $sidebar-light-submenu-active-bg;
            }
          }
        }
      }
    }
  }
}

[class*='sidebar-dark-'] {
  // Sidebar background color
  background-color: $sidebar-dark-bg;

  // User Panel (resides in the sidebar)
  .user-panel {
    a:hover {
      color: $sidebar-dark-hover-color;
    }

    .status {
      background: $sidebar-dark-hover-bg;
      color: $sidebar-dark-color;

      &:hover,
      &:focus,
      &:active {
        background: darken($sidebar-dark-hover-bg, 3%);
        color: $sidebar-dark-hover-color;
      }
    }

    .dropdown-menu {
      @include box-shadow(0 2px 4px rgba(0, 0, 0, .4));
      border-color: darken($sidebar-dark-hover-bg, 5%);
    }

    .dropdown-item {
      color: $body-color;
    }
  }

  // Sidebar Menu. First level links
  .nav-sidebar > .nav-item {
    // links
    > .nav-link {
      // border-left: 3px solid transparent;
      &:active {
        color: $sidebar-dark-color;
      }
    }

    // Hover and active states
    &.menu-open > .nav-link,
    &:hover > .nav-link,
    & > .nav-link:focus  {
      background-color: $sidebar-dark-hover-bg;
      color: $sidebar-dark-hover-color;
    }

    > .nav-link.active {
      color: $sidebar-dark-hover-color;

      @if $enable-shadows {
        box-shadow: map-get($elevations, 1);
      }
    }

    // First Level Submenu
    > .nav-treeview {
      background: $sidebar-dark-submenu-bg;
    }
  }

  // Section Heading
  .nav-header {
    background: inherit; //darken($sidebar-dark-bg, 3%);
    color: lighten($sidebar-dark-color, 5%);
  }

  // All links within the sidebar menu
  .sidebar {
    a {
      color: $sidebar-dark-color;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  // All submenus
  .nav-treeview {
    > .nav-item {
      > .nav-link {
        color: $sidebar-dark-submenu-color;

        &:hover,
        &:focus {
          background-color: $sidebar-dark-submenu-hover-bg;
          color: $sidebar-dark-submenu-hover-color;
        }
      }

      > .nav-link.active {
        &,
        &:hover,
        &:focus {
          background-color: $sidebar-dark-submenu-active-bg;
          color: $sidebar-dark-submenu-active-color;
        }
      }
    }
  }

  // Flat Style
  .nav-flat {
    .nav-item {
      .nav-treeview {
        .nav-treeview {
          border-color: $sidebar-dark-submenu-active-bg;
        }

        > .nav-item {
          > .nav-link {
            &,
            &.active {
              border-color: $sidebar-dark-submenu-active-bg;
            }
          }
        }
      }
    }
  }
}

// Sidebar variants
@each $name, $color in $theme-colors {
  .sidebar-dark-#{$name},
  .sidebar-light-#{$name} {
    @include sidebar-color($color)
  }
}

@each $name, $color in $colors {
  .sidebar-dark-#{$name},
  .sidebar-light-#{$name} {
    @include sidebar-color($color)
  }
}

.sidebar-mini .main-sidebar:not(.sidebar-no-expand),
.sidebar-mini-md .main-sidebar:not(.sidebar-no-expand),
.sidebar-mini .main-sidebar:not(.sidebar-no-expand):hover,
.sidebar-mini-md .main-sidebar:not(.sidebar-no-expand):hover,
.sidebar-mini .main-sidebar.sidebar-focused,
.sidebar-mini-md .main-sidebar.sidebar-focused {
 .nav-compact.nav-sidebar.nav-child-indent:not(.nav-flat) .nav-treeview {
    padding-left: 1rem;
    margin-left: -.5rem;
  }
}

// Nav Flat
.nav-flat {
  margin: (-$sidebar-padding-x/2) (-$sidebar-padding-x) 0;

  .nav-item {
    > .nav-link {
      border-radius: 0;
      margin-bottom: 0;

      > .nav-icon {
        margin-left: .55rem;
      }
    }
  }

  &:not(.nav-child-indent) {
    .nav-treeview {  
      .nav-item {
        > .nav-link {
          > .nav-icon {
            margin-left: .4rem;
          }
        }
      }
    }
  }

  &.nav-child-indent {
    .nav-treeview {
      padding-left: 0;

      .nav-icon {
        margin-left: .85rem;
      }

      .nav-treeview {
        border-left: .2rem solid;

        .nav-icon {
          margin-left: 1.15rem;
        }

        .nav-treeview {
          .nav-icon {
            margin-left: 1.45rem;
          }

          .nav-treeview {
            .nav-icon {
              margin-left: 1.75rem;
            }

            .nav-treeview {
              .nav-icon {
                margin-left: 2.05rem;
              }
            }
          }
        }
      }
    }
  }

  .sidebar-collapse &.nav-child-indent {
    .nav-treeview {
      .nav-icon {
        margin-left: .55rem;
      }

      .nav-link {
        padding-left: calc(#{$nav-link-padding-x} - .2rem);
      }

      .nav-treeview {
        .nav-icon {
          margin-left: .35rem;
        }

        .nav-treeview {
          .nav-icon {
            margin-left: .15rem;
          }

          .nav-treeview {
            .nav-icon {
              margin-left: -.15rem;
            }

            .nav-treeview {
              .nav-icon {
                margin-left: -.35rem;
              }
            }
          }
        }
      }
    }
  }

  .sidebar-mini .main-sidebar:not(.sidebar-no-expand):hover &,
  .sidebar-mini-md .main-sidebar:not(.sidebar-no-expand):hover &,
  .sidebar-mini .main-sidebar.sidebar-focused &,
  .sidebar-mini-md .main-sidebar.sidebar-focused & {
    &.nav-compact.nav-sidebar .nav-treeview {
      .nav-icon {
        margin-left: .4rem;
      }
    }

    &.nav-sidebar.nav-child-indent .nav-treeview {
      .nav-icon {
        margin-left: .85rem;
      }

      .nav-treeview {
        .nav-icon {
          margin-left: 1.15rem;
        }

        .nav-treeview {
          .nav-icon {
            margin-left: 1.45rem;
          }

          .nav-treeview {
            .nav-icon {
              margin-left: 1.75rem;
            }

            .nav-treeview {
              .nav-icon {
                margin-left: 2.05rem;
              }
            }
          }
        }
      }
    }
  }

  .nav-icon {
    @include transition(margin-left $transition-fn $transition-speed);
  }

  .nav-treeview {
    .nav-icon {
      margin-left: -.2rem;
    }
  }

  &.nav-sidebar > .nav-item {
    .nav-treeview,
    > .nav-treeview {
      background: rgba($white, .05);

      .nav-item {
        > .nav-link {
          border-left: .2rem solid;
        }
      }
    }
  }
}

.nav-legacy {
  margin: (-$sidebar-padding-x/2) (-$sidebar-padding-x) 0;

  &.nav-sidebar .nav-item {
    > .nav-link {
      border-radius: 0;
      margin-bottom: 0;

      > .nav-icon {
        margin-left: .55rem;

        .text-sm & {
          margin-left: .75rem;
        }
      }
    }
  }

  &.nav-sidebar > .nav-item {
    > .nav-link {
      &.active {
        background: inherit;
        border-left: 3px solid transparent;
        box-shadow: none;

        > .nav-icon {
          margin-left: calc(.55rem - 3px);
          
          .text-sm & {
            margin-left: calc(.75rem - 3px);
          }
        }
      }
    }
  }

  .text-sm &.nav-sidebar.nav-flat .nav-treeview {
    .nav-item {
      > .nav-link {
        > .nav-icon {
          margin-left: calc(.75rem - 3px);
        }
      }
    }
  }

  .sidebar-mini &,
  .sidebar-mini-md & {
    > .nav-item .nav-link {
      .nav-icon {
        @include transition(margin-left $transition-fn $transition-speed);
        margin-left: .75rem;
      }
    }
  }

  .sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused &.nav-child-indent,
  .sidebar-mini.sidebar-collapse .main-sidebar:hover &.nav-child-indent,
  .sidebar-mini-md.sidebar-collapse .main-sidebar.sidebar-focused &.nav-child-indent,
  .sidebar-mini-md.sidebar-collapse .main-sidebar:hover &.nav-child-indent {
    .nav-treeview {
      padding-left: 1rem;

      .nav-treeview {
        padding-left: 2rem;
        margin-left: -1rem;
      }
    }
  }

  .sidebar-mini.sidebar-collapse.text-sm .main-sidebar.sidebar-focused &.nav-child-indent,
  .sidebar-mini.sidebar-collapse.text-sm .main-sidebar:hover &.nav-child-indent,
  .sidebar-mini-md.sidebar-collapse.text-sm .main-sidebar.sidebar-focused &.nav-child-indent,
  .sidebar-mini-md.sidebar-collapse.text-sm .main-sidebar:hover &.nav-child-indent {
    .nav-treeview {
      padding-left: .5rem;

      .nav-treeview {
        padding-left: 1rem;
        margin-left: -.5rem;
      }
    }
  }

  .sidebar-mini.sidebar-collapse &,
  .sidebar-mini-md.sidebar-collapse & {
    > .nav-item > .nav-link {
      .nav-icon {
        margin-left: .55rem;
      }

      &.active {
        > .nav-icon{
          margin-left: .36rem;
        }
      }
    }

    &.nav-child-indent {   
      .nav-treeview {
        .nav-treeview {
          padding-left: 0;
          margin-left: 0;
        }
      }
      }
    }


  .sidebar-mini.sidebar-collapse.text-sm &,
  .sidebar-mini-md.sidebar-collapse.text-sm & {
    > .nav-item > .nav-link {
      .nav-icon {
        margin-left: .75rem;
      }

      &.active {
        > .nav-icon{
          margin-left: calc(.75rem - 3px);
        }
      }
    }
  }

  [class*='sidebar-dark'] & {
    &.nav-sidebar > .nav-item {
      .nav-treeview,
      > .nav-treeview {
        background: rgba($white, .05);
      }

      > .nav-link.active {
        color: $sidebar-dark-active-color;
      }
    }

    .nav-treeview > .nav-item > .nav-link {
      &.active,
      &:focus,
      &:hover {
        background: none;
        color: $sidebar-dark-active-color;
      }
    }
  }

  [class*='sidebar-light'] & {
    &.nav-sidebar > .nav-item {
      .nav-treeview,
      > .nav-treeview {
        background: rgba($black, .05);
      }

      > .nav-link.active {
        color: $sidebar-light-active-color;
      }
    }

    .nav-treeview > .nav-item > .nav-link {
      &.active,
      &:focus,
      &:hover {
        background: none;
        color: $sidebar-light-active-color;
      }
    }
  }
}


.nav-collapse-hide-child {
  .menu-open > .nav-treeview {
    max-height: min-content;
    opacity: 1;
  }

  .sidebar-collapse & {
    .menu-open > .nav-treeview {
      max-height: 0;
      opacity: 0;
    }
  }

  .sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused &,
  .sidebar-mini.sidebar-collapse .main-sidebar:hover &,
  .sidebar-mini-md.sidebar-collapse .main-sidebar.sidebar-focused &,
  .sidebar-mini-md.sidebar-collapse .main-sidebar:hover & {
    .menu-open > .nav-treeview {
      max-height: min-content;
      opacity: 1;
    }
  }
}

// Nav Compact
.nav-compact {
  .nav-link,
  .nav-header {
    padding-top: ($nav-link-padding-y / 2);
    padding-bottom: ($nav-link-padding-y / 2);
  }

  .nav-header:not(:first-of-type) {
    padding-top: ($nav-link-padding-y * 1.5);
    padding-bottom: ($nav-link-padding-y / 2);
  }

  .nav-link > .right,
  .nav-link > p > .right {
    top: .465rem;
  }

  .text-sm & {
    .nav-link > .right,
    .nav-link > p > .right {
      top: .7rem;
    }
  }
}

// Sidebar Form Control
[class*='sidebar-dark'] {
  .form-control-sidebar,
  .btn-sidebar {
    background: lighten($sidebar-dark-bg, 5%);
    border: 1px solid lighten($sidebar-dark-bg, 15%);
    color: lighten(color-yiq(lighten($sidebar-dark-bg, 5%)), 15%);
  }

  .form-control-sidebar:focus,
  .btn-sidebar:focus {
    border: 1px solid lighten($sidebar-dark-bg, 30%);
  }

  .btn-sidebar:hover {
    background: lighten($sidebar-dark-bg, 7.5%);
  }

  .btn-sidebar:focus {
    background: lighten($sidebar-dark-bg, 10%);
  }
}

[class*='sidebar-light'] {
  .form-control-sidebar,
  .btn-sidebar {
    background: darken($sidebar-light-bg, 5%);
    border: 1px solid darken($sidebar-light-bg, 15%);
    color: color-yiq(darken($sidebar-light-bg, 5%));
  }

  .form-control-sidebar:focus,
  .btn-sidebar:focus {
    border: 1px solid darken($sidebar-light-bg, 30%);
  }

  .btn-sidebar:hover {
    background: darken($sidebar-light-bg, 7.5%);
  }

  .btn-sidebar:focus {
    background: darken($sidebar-light-bg, 10%);
  }
}
